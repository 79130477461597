.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Visina čitave stranice */
    background-color: rgba(255, 255, 255, 0.8); /* Pozadina sa prozirnošću */
}

.loader img {
    width: 100px; /* Možeš prilagoditi veličinu slike */
    animation: spin 1s linear infinite; /* Animacija rotacije */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
